import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {userTypes} from "../types/user";
import {useCallback} from "react";

export const userState = atom<userTypes>({
    key: 'user',
    default: {
        seq: '',
        email: '',
        statusLevels: '',
        name: '',
        role: '',
    }
});

export const useRecoilGetUser = () => {
    return useRecoilValue(userState);
};

export const useRecoilSetUser = () => {
    const setUser = useSetRecoilState(userState);

    return useCallback(({seq, email, name, role, statusLevels}: Pick<userTypes, 'seq' | 'email' | 'name' | 'role' | 'statusLevels'>) => {
        setUser({
            seq,
            email,
            name,
            role,
            statusLevels,
        });
    }, []);
};


export const useRecoilDeleteUser = () => {
    const setUser = useSetRecoilState(userState);

    return useCallback(() => {
        setUser({
            seq: '',
            email: '',
            name: '',
            role: '',
        });
    }, []);
};
