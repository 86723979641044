import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {lazy, Suspense, useEffect} from "react";
import {useLoginCheck} from "../../api/user";
import {useRecoilSetUser} from "../../recoil/user";

const Login = lazy(() => import('../login').then((module) => ({default: module.Login})));
const AddUser = lazy(() => import('../user/add').then((module) => ({default: module.AddUser})));
const User = lazy(() => import('../user').then((module) => ({default: module.User})));
const EditUser = lazy(() => import('../user/edit').then((module) => ({default: module.EditUser})));
const Privacy = lazy(() => import('../privacy').then((module) => ({default: module.Privacy})));
const DetailUser = lazy(() => import('../user/detail').then((module) => ({default: module.DetailUser})));
const Order = lazy(() => import('../order').then((module) => ({default: module.Order})));
const EditOrder = lazy(() => import('../order/edit').then((module) => ({default: module.EditOrder})));
const AddOrder = lazy(() => import('../order/add').then((module) => ({default: module.AddOrder})));
const DetailOrder = lazy(() => import('../order/detail').then((module) => ({default: module.DetailOrder})));
const Setting = lazy(() => import('../setting').then((module) => ({default: module.Setting})));
const Question = lazy(() => import('../question').then((module) => ({default: module.Question})));
const EditQuestion = lazy(() => import('../question/edit').then((module) => ({default: module.EditQuestion})));
const AddQuestion = lazy(() => import('../question/add').then((module) => ({default: module.AddQuestion})));
const DetailQuestion = lazy(() => import('../question/detail').then((module) => ({default: module.DetailQuestion})));

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const setUser = useRecoilSetUser();
    const {mutate} = useLoginCheck();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname === '/login') return;
        if (location.pathname === '/') navigate('/user');
        const token = localStorage.getItem('x_auth');

        mutate({token}, {
            onSuccess: async (data) => {
                setUser({seq: data.seq, email: data.email, name: data.name, role: data.role, statusLevels: data.statusLevels,});
            },
            onError: async () => {
                if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location.pathname]);

    return (
        <Suspense fallback={<div/>}>
            <Routes>
                <Route path="/login" element={<Login/>}/>

                <Route path="/user" element={<User/>}/>
                <Route path="/user/:seq" element={<DetailUser/>}/>
                <Route path="/user/add" element={<AddUser/>}/>
                <Route path="/user/edit/:seq" element={<EditUser/>}/>

                <Route path="/question" element={<Question/>}/>
                <Route path="/question/:seq" element={<DetailQuestion/>}/>
                <Route path="/question/add" element={<AddQuestion/>}/>
                <Route path="/question/edit/:seq" element={<EditQuestion/>}/>

                <Route path="/order" element={<Order/>}/>
                <Route path="/order/:seq" element={<DetailOrder/>}/>
                <Route path="/order/add" element={<AddOrder/>}/>
                <Route path="/order/edit/:seq" element={<EditOrder/>}/>

                <Route path="/setting" element={<Setting/>}/>

                <Route path="/privacy" element={<Privacy/>}/>
            </Routes>
        </Suspense>
    );
}
