import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {userTypes} from "../types/user";
import {privacyTypes} from "../types/privacy";
import {useRecoilDeleteUser} from "../recoil/user";
import {useCustomSearchParams} from "../utils/useCustomSearchParams";

export const useLogout = () => {
    const token = localStorage.getItem('x_auth');
    const api = useCallback(() => {
        return axios.post(`/user/logout`, [], {headers: {'x-auth': token}});
    }, []);
    return useMutation(
        async () => {
            const response = await api();
            localStorage.removeItem('x_auth');
            localStorage.removeItem('role');
            return response.data;
        }
    );
}

export const useLogin = () => {
    const api = useCallback((data: Pick<userTypes, 'email' | 'password'>) => {
        return axios.post(`/user/login`, data);
    }, []);

    return useMutation(
        async (data: Pick<userTypes, 'email' | 'password'>) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useLoginCheck = () => {
    const api = useCallback((token: string) => {
        return axios.post('/user/login/check', token);
    }, []);

    return useMutation(
        async (data: any) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateUser = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback((data: userTypes) => {
        return axios.put('/user', data, {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateUsersUseYn = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback((data: userTypes) => {
        return axios.put('/user/useYn', data, {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteUsersUseYn = () => {
    const token = localStorage.getItem('x_auth');
    const api = useCallback((data: userTypes) => {
        return axios.put(`/user/useYn/leave`, data,  {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}


export const useUpdateUsersTestLevel = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback((data: userTypes) => {
        return axios.put('/user/student/testLevel', data, {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useUpdateUsersLevel = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback((data: userTypes) => {
        return axios.put('/user/student/level', data, {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertUser = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback((data: userTypes) => {
        return axios.post('/user', data, {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteUser = () => {
    const token = localStorage.getItem('x_auth');
    const api = useCallback(({seq}: Pick<userTypes, 'seq'>) => {
        return axios.delete(`/user/${seq}`,  {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async ({seq}: Pick<userTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useNormalUser = () => {
    const token = localStorage.getItem('x_auth');
    const api = useCallback(({seq}: Pick<userTypes, 'seq'>) => {
        return axios.put(`/user/normal/${seq}`, '', {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async ({seq}: Pick<userTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useLeaveUser = () => {
    const token = localStorage.getItem('x_auth');
    const api = useCallback(({seq}: Pick<userTypes, 'seq'>) => {
        return axios.put(`/user/leave/${seq}`, '', {headers: {'x-auth': token}});
    }, []);

    return useMutation(
        async ({seq}: Pick<userTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useUpdatePassword = () => {
    const api = useCallback(({password}: { password: string }) => {
        return axios.put('/user/password', {password});
    }, []);

    return useMutation(
        async ({password}: { password: string }) => {
            const response = await api({password});
            return response.data;
        },
    );
}

export const useFindUser = ({seq}: userTypes) => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback(() => {
        return axios.get(`/user/${seq}`, {headers: {'x-auth': token}});
    }, []);

    return useQuery(
        ['userDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
    );
}

export const useFindUsers = () => {
    const token = localStorage.getItem('x_auth');
    const {page, search, category} = useCustomSearchParams().get();

    const api = useCallback(() => {
        return axios.post(`/user/list`, {page, search, category}, {headers: {'x-auth': token}});
    }, [page, search, category]);

    return useQuery(
        ['user', {page, search, category}],
        async () => {
            const response = await api();
            return response.data;
        }, {
            keepPreviousData: true
        }
    );
}

export const useFindManagers = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback(() => {
        return axios.get(`/user/manager/list`, {headers: {'x-auth': token}});
    }, []);

    return useQuery(
        ['manager'],
        async () => {
            const response = await api();
            return response.data;
        }, {
            keepPreviousData: true
        }
    );
}

export const useFindAcademies = () => {
    const token = localStorage.getItem('x_auth')
    const api = useCallback(() => {
        return axios.get(`/user/academy/list`, {headers: {'x-auth': token}});
    }, []);

    return useQuery(
        ['academy'],
        async () => {
            const response = await api();
            return response.data;
        }, {
            keepPreviousData: true
        }
    );
}

export const useUserFindPw = () => {
    const api = useCallback((data: userTypes) => {
        return axios.post('user/find/pw', data);
    }, []);

    return useMutation(
        async (data: userTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}
